const apiBase =
  process.env.NODE_ENV === "production"
    ? window.location.hostname
    : "http://localhost:8080";

const redirect_uri =
  process.env.NODE_ENV === "production"
    ? "https://" + window.location.hostname
    : "http://localhost:9000";


module.exports = {
  // ports
  serverPort: 8080,
  //This app url
  appURL: apiBase,
  appName: "MYBE Business",
  appVersion: 1.0,
  appVersionMessage: "Business Service Launch!",
  adminTenant: "9f630112-885b-49e2-9045-9376a57a33e7",

  //Redirect url for authing
  redirect_uri: redirect_uri,
};
